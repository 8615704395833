.progressBar {
  position: relative;
  margin: 24px auto 0;
  background-color: var(--color-dark-grey);
  width: 100%;
  height: 1px;
}

.progressBar__current {
  position: absolute;
  top: 0;
  transition: left 0.3s ease-out;
  margin: 0;
  border: none;
  background-color: var(--color-accent);
  height: 1px;
}

.carousel .progressBar {
  display: none;
}

@media screen and (max-width: 600px) {
  .carousel .progressBar {
    display: block;
    margin: 0 auto;
    width: 96vw;
  }
}
